import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  useTheme,
  Typography,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { classicGames } from "../../../data/historicgames/classicGames";
import GameViewer from "../../../components/GameViewer";
import ExpandableTypography from "../../../components/ExpandableTypograph";

function ClassicGames() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const defaultEvent =
    "The Opera Game (P. Morphy vs Duke Karl and Count Isouard)";

  const [selectedEventIndex, setSelectedEventIndex] = useState(0);

  useEffect(() => {
    const initialEventIndex = classicGames.findIndex(
      (event) => event.Name === defaultEvent
    );
    if (initialEventIndex !== -1) {
      setSelectedEventIndex(initialEventIndex);
    }
  }, [defaultEvent]);

  const handleEventChange = (event) => {
    setSelectedEventIndex(event.target.value);
  };

  return (
    <Box>
      <ContentHeader
        title="Classic Chess Games"
        subtitle="Explore and play through Classic chess games, featuring matches from some of the greatest players in history."
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
      />
      <Helmet>
        <title>Classic Chess Games</title>
        <meta
          name="description"
          content="Explore and play through Classic chess games, featuring matches from some of the greatest players in history."
        />
        <meta property="og:title" content="Classic Chess Games" />
        <meta
          property="og:description"
          content="Explore and play through Classic chess games, featuring matches from some of the greatest players in history."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/library/classicgames.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/classicgames`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Historic Chess Games" />
        <meta
          name="twitter:description"
          content="Explore and play through classic chess games, featuring matches from some of the greatest players in history."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/library/classicgames.png`}
        />
      </Helmet>
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <FormControl variant="outlined" margin="normal">
            <Select
              id="event-select"
              value={selectedEventIndex}
              onChange={handleEventChange}
              sx={{ maxWidth: "500px" }}
            >
              {classicGames.map((event, index) => (
                <MenuItem key={index} value={index}>
                  <Typography component="span">{event.Name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {classicGames[selectedEventIndex] && (
          <Box>
            <Typography sx={{ pb: 0 }}>
              Site: <b>{classicGames[selectedEventIndex].Site}</b>, Date:{" "}
              <b>{classicGames[selectedEventIndex].Date}</b>
            </Typography>
            <ExpandableTypography
              text={classicGames[selectedEventIndex].Description}
              charLimit="160"
              buttonPosition="right"
              textWidth="700px"
            />
            <Box>
              <GameViewer
                BlackPlayer={classicGames[selectedEventIndex].Black}
                BlackElo={classicGames[selectedEventIndex].BlackElo}
                WhitePlayer={classicGames[selectedEventIndex].White}
                WhiteElo={classicGames[selectedEventIndex].WhiteElo}
                Date={classicGames[selectedEventIndex].Date}
                Result={classicGames[selectedEventIndex].Result}
                Round={classicGames[selectedEventIndex].Round}
                PlyCount={classicGames[selectedEventIndex].PlyCount}
                Moves={classicGames[selectedEventIndex].Moves}
                WhiteImage={classicGames[selectedEventIndex].White.split(
                  ", "
                )[0].toUpperCase()}
                BlackImage={classicGames[selectedEventIndex].Black.split(
                  ", "
                )[0].toUpperCase()}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ClassicGames;
