import React from "react";
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useTheme,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PatreonIcon from "../../../icons/PatreonIcon";

const About = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Helmet>
        <title>About Chessboard Magic</title>
        <meta
          name="description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta property="og:title" content="About Chessboard Magic" />
        <meta
          property="og:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          property="og:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
        <meta property="og:url" content="https://chessboardmagic.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Chessboard Magic" />
        <meta
          name="twitter:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          name="twitter:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
      </Helmet>
      <ContentHeader
        title="About Chessboard Magic"
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
        }}
      >
        <Box id="about" sx={{ flex: 1 }}>
          <Box>
            <p>
              This website is managed and operated by Toan Hoang, a passionate
              chess enthusiast and technology aficionado. Toan embarked on his
              chess journey in November 2020, diving deep into the intricacies
              and strategies of the game. His profound interest in chess,
              coupled with his expertise in technology, inspired him to create
              this platform dedicated to the world of chess.
            </p>
            <p>
              Recognizing the potential to merge his two passions, Toan set out
              to develop a comprehensive series of chess games and tools
              designed to enhance the experience for players of all skill
              levels. From innovative training tools to engaging chess puzzles
              and interactive games, this website offers a variety of resources
              to help both beginners and advanced players improve their skills
              and enjoy the game.
            </p>
            <p>
              This project is a labor of love and is continually evolving. Toan
              is committed to regularly updating the site with new content,
              features, and improvements. Visitors can look forward to a steady
              stream of exciting additions and updates in the near future, as
              Toan works tirelessly to make this platform a valuable resource
              for the chess community.
            </p>
            <p>
              Stay tuned for more content, tools, and games that will enrich
              your chess-playing experience. Whether you're just starting out or
              looking to refine your skills, this website is here to support
              your journey through the fascinating world of chess.
            </p>
            <p>
              I would love to connect with you! Feel free to reach out via
              Chess.com or Lichess.org. If you'd like to support me, you can do
              so on Patreon:
            </p>
          </Box>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2, mr: 2, textTransform: "none" }}
              href="https://lichess.org/@/HollowLeaf"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/img/lichessdotorg.png`}
                  alt="lichess.org"
                  style={{ width: 24, height: 24 }}
                />
              }
            >
              HollowLeaf
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2, mr: 2, textTransform: "none" }}
              href="https://www.chess.com/member/hollowleaf"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/img/chessdotcom.png`}
                  alt="chess.com"
                  style={{ width: 24, height: 24 }}
                />
              }
            >
              HollowLeaf
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2, textTransform: "none" }}
              href="https://patreon.com/ChessboardMagic"
              target="_blank"
              rel="noopener noreferrer"
              startIcon={<PatreonIcon sx={{ width: 24, height: 24 }} />}
            >
              Patreon
            </Button>
          </Box>
        </Box>
        <Box id="version" sx={{ flex: 1, paddingBottom: "20px" }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Version 0.3</b>
                {"  "}8th August 2024{"  "}
                <span style={{ color: "#555555" }}>[Explore Chess]</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              This update adds the long awaiting Analytics section to the
              website with our first tool User Scount. This is supplemented by a
              sizable game library as well as new tools and games.
              <Typography>
                <p>
                  <strong>New Games</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Chess Crossword: Solve chess-themed crosswords and improve
                    your chess knowledge while having fun.
                  </li>
                  <li>
                    Chess Image Puzzle: "Engage in jigsaw-like puzzles featuring
                    images of renowned chess players, piecing together each
                    portrait for a challenging experience.
                  </li>
                </ul>
                <p>
                  <strong>Analytics</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    User Scout (v0.1): Added a new application that will allow
                    you to explore Lichess profiles. You can see statistics as
                    well as explore opening strengths and weaknesses.
                  </li>
                </ul>
                <p>
                  <strong>Tools</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Notation Trainer Update: Updated so that the games are based
                    on the World Championship Games, or a my selection of
                    Classic Chess Games. Additional fixes include the addition
                    of arrows to show the current move as well as moving on
                    after three incorrect guesses.
                  </li>
                  <li>
                    Gif Generator: Turn Chess PGNs into visually engaging
                    sequences effortlessly with our GIF Generator. You may have
                    seen these on social media, and now you will be able to
                    generate your own.
                  </li>
                </ul>
                <p>
                  <strong>New Library Items</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    World Chess Championships: Explore and play through the
                    World Chess Championship games (from 1,886 to 2023),
                    featuring matches from the world's top players.
                  </li>
                  <li>
                    Classic Chess Games: Explore and play through Classic chess
                    games, featuring matches from some of the greatest players
                    in history.
                  </li>
                  <li>
                    FIDE Rules of Basic Play: Basic chess rules cover piece
                    movement, check and checkmate, castling, pawn promotion, and
                    the initial setup of the board.
                  </li>
                  <li>
                    FIDE Rules of Competitive Play: Competitive chess rules
                    ensure fair play, covering player conduct, piece movement,
                    clock use, and dispute resolution.
                  </li>
                </ul>
                <p>
                  <strong>Other Updates</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Home Page Update to maximise the space and give a better
                    experiene.
                  </li>
                  <li>Increased the number of games in Guess the Elo.</li>
                  <li>Increased the number of positions in Guess the Eval.</li>
                  <li>
                    Included a profile so that you can login to see your
                    statistics as well choose your chessboard and piece theme.
                  </li>
                  <li>
                    Added a host of different pieces from Lichess as well as a
                    host of different board colour combinations.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Version 0.2</b>
                {"  "}5th July 2024{"  "}
                <span style={{ color: "#555555" }}>[Alphabet of Chess]</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              This update expanded on the initial release by adding several
              word-based puzzles and introducing a library section. Significant
              work was done on the technology stack to enhance performance and
              usability, with processes implemented to ensure long-term success.
              <Typography>
                <p>
                  <strong>New Games</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Chess Hangman: Guess the chess-related word before the
                    hangman is complete.
                  </li>
                  <li>
                    Chess Anagrams: Unscramble the letters to form chess-related
                    terms.
                  </li>
                  <li>
                    Chess Wordsearch: Find chess terms hidden in a grid of
                    letters.
                  </li>
                </ul>
                <p>
                  <strong>Tools</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Notation Trainer: To help you develop your Chess notation
                    skills.
                  </li>
                </ul>
                <p>
                  <strong>New Library Items</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Chess Quotes: A collection of famous chess quotes.</li>
                  <li>
                    Chess Terms: Definitions and explanations of common chess
                    terminology.
                  </li>
                </ul>
                <p>
                  <strong>Other Updates</strong>
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Added Time Controls for Guess the Elo.</li>
                  <li>
                    Updated the Help section for Guess the Elo to specify that
                    the games are sampled from Lichess in 2020.
                  </li>
                  <li>Increased the number of games in Guess the Elo.</li>
                  <li>Increased the number of positions in Guess the Eval.</li>
                  <li>Showed the Computer Line for Guess the Eval.</li>
                  <li>Included links to our Facebook and Twitter profiles.</li>
                  <li>Several cosmetic adjustments.</li>
                  <li>Added Dark and Light Mode.</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <b>Version 0.1</b>
                {"  "}27th May 2024{"  "}
                <span style={{ color: "#555555" }}>[Initial Release]</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              The purpose of this release was to launch the initial version of
              the website with a few puzzles. The goal was to test the
              technology stack as well as the overall design and user
              experience.
              <Typography>
                <p>Games</p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Guess the Elo: Estimate player ratings based on their games.
                  </li>
                  <li>
                    Play The Opening: Reproduce the opening from its name.
                  </li>
                  <li>Guess the Opening: A chess-themed version of Wordle.</li>
                  <li>
                    Guess the Eval: Predict the evaluation of chess positions.
                  </li>
                  <li>Chess Slide: Solve sliding puzzles with chess pieces.</li>
                </ul>
                <p>Tools</p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Fischer Random Generator: Generate random chess positions.
                  </li>
                  <li>Opening Explorer: Explore different chess openings.</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
