import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const GuessTheEloHelp = () => {
  return (
    <Box>
      <Typography>
        Welcome to "Guess the Elo," the ultimate chess challenge for all chess
        enthusiasts! Test your chess expertise and try to guess the Elo ratings
        of the players based on their moves and strategies.
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Watch the Game"
            secondary="Look at the chess game between two players. Pay attention to how they move their pieces on the board."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Think About Ratings"
            secondary="Try to guess how good the players are at chess. Are they beginners, intermediate, or advanced players."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Guess Their Ratings"
            secondary="Tell us what you think the ratings of the white and black players are. Ratings are based on games from Lichess.org in 2020. Ratings based off Lichess.org in 2020."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="See If You're Right"
            secondary="After you make your guesses, find out if you guessed correctly. Were you able to figure out the players' skill levels."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Keep Playing"
            secondary="Try more games and keep improving your guessing skills. Can you become a rating expert?"
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default GuessTheEloHelp;
