import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

const HangmanHelp = () => {
  return (
    <>
      <Typography>
        Welcome to Chess Hangman! In this exciting twist on the classic game,
        you'll be challenged to guess a chess-related word. Whether it's a chess
        term, player, opening, or engine, put your chess knowledge to the test!
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Start the Game"
            secondary="Click the Play button to start a new game. A random chess-related word will be selected, and you need to guess it by suggesting letters."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Guess the Letters"
            secondary="Keep guessing letters to reveal as much of the word as possible. Each correct guess will show the letters in their correct positions."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Avoid Wrong Guesses"
            secondary="You have 5 chances to guess incorrect letters. If you guess a letter that is not in the word, you get closer to completing the hangman. After 5 incorrect guesses, the game is over."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Game Over"
            secondary="If you guess all the letters in the word correctly before reaching 5 incorrect guesses, you win! If you reach 5 incorrect guesses, the game is over and the word is revealed."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Chess Knowledge"
            secondary="This game tests your familiarity with chess terms, players, openings, and engines. The more you know, the better your chances of guessing the word!"
          />
        </ListItem>
      </List>
    </>
  );
};

export default HangmanHelp;
