import React, { useState, useEffect, useContext } from "react";
import {
  Drawer,
  List,
  ListItemText,
  Container,
  ListItemIcon,
  ListItemButton,
  Box,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ColorModeContext, tokens } from "../styles/theme";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import OpenWithRoundedIcon from "@mui/icons-material/OpenWithRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import AbcRoundedIcon from "@mui/icons-material/AbcRounded";
import HandymanIcon from "@mui/icons-material/Handyman";
import ImportExportRoundedIcon from "@mui/icons-material/ImportExportRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import PatternRoundedIcon from "@mui/icons-material/PatternRounded";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
//import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
//import RocketRoundedIcon from "@mui/icons-material/RocketRounded";
//import AllInclusiveRoundedIcon from "@mui/icons-material/AllInclusiveRounded";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import TroubleshootRoundedIcon from "@mui/icons-material/TroubleshootRounded";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import SettingsInputAntennaRoundedIcon from "@mui/icons-material/SettingsInputAntennaRounded";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import FormatQuoteRoundedIcon from "@mui/icons-material/FormatQuoteRounded";
import GrassRoundedIcon from "@mui/icons-material/GrassRounded";
import HubRoundedIcon from "@mui/icons-material/HubRounded";
import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import IsoIcon from "@mui/icons-material/Iso";
import InfoIcon from "@mui/icons-material/Info";
import ShuffleRoundedIcon from "@mui/icons-material/ShuffleRounded";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
//import CenterFocusStrongRoundedIcon from "@mui/icons-material/CenterFocusStrongRounded";
import GavelRoundedIcon from "@mui/icons-material/GavelRounded";
//import StorageIcon from "@mui/icons-material/Storage";
//import BinocularsIcon from "../icons/BinocularsIcon";
import HistoryIcon from "../icons/HistoryIcon";
import CompetitionIcon from "../icons/CompetitionIcon";
import CrosswordIcon from "../icons/CrosswordIcon";

import InsightsIcon from "@mui/icons-material/Insights";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PatreonIcon from "../icons/PatreonIcon";
import IconButton from "@mui/material/IconButton";
import { chessquotes } from "../data/quotes/chessquotes";

import { useUser } from "../context/UserContext";
import { auth } from "../config/firebase";
import { logout } from "../features/Authentication";
import GifIcon from "../icons/GifIcon";

const drawerWidth = 220;
const submenuWidth = 250;

const MenuListItem = ({
  text,
  isSmallScreen,
  activeMenuItem,
  onMouseEnter,
  onMouseLeave,
  onClick,
  icon,
  to,
}) => {
  const listItemStyle = {
    height: "48px",
    paddingLeft: "16px",
    paddingRight: "0px",
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    minWidth: "auto",
    marginRight: "8px",
  };

  return (
    <ListItemButton
      component={Link}
      to={to}
      selected={activeMenuItem === text}
      onMouseEnter={() => onMouseEnter(text)}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      sx={listItemStyle}
    >
      <ListItemIcon sx={iconStyle}>{icon}</ListItemIcon>
      <ListItemText primary={isSmallScreen ? "" : text} />
    </ListItemButton>
  );
};

const SubmenuItem = ({ text, to, onClick, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const listItemStyle = {
    height: "48px",
    display: "flex",
    alignItems: "center",
  };

  const iconStyle = {
    minWidth: "auto",
    marginRight: "8px",
  };

  const listItemButtonStyle = {
    ...listItemStyle,
    "&:hover": {
      backgroundColor: colors.black[200],
    },
  };

  return (
    <ListItemButton
      sx={listItemButtonStyle}
      onClick={onClick}
      component={Link}
      to={to}
    >
      <ListItemIcon sx={iconStyle}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};

const AppSideBar = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [isSubmenuItemClicked, setIsSubmenuItemClicked] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [quote, setQuote] = useState({ Quote: "", Author: "" }); // State for the quote
  const colorMode = useContext(ColorModeContext);
  const { userData } = useUser();

  let submenuCloseTimeout;

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };

  /*const handleNewQuote = () => {
    const randomIndex = Math.floor(Math.random() * chessquotes.length);
    const selectedQuote = chessquotes[randomIndex];
    setQuote(selectedQuote);
  };*/

  useEffect(() => {
    const filteredQuotes = chessquotes.filter(
      (quote) => quote.Quote.length < 120
    );
    let randomIndex = Math.floor(Math.random() * filteredQuotes.length);
    let quote = filteredQuotes[randomIndex];
    setQuote(quote);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleListItemMouseEnter = (menuItem) => {
    if (menuItem !== "") {
      setIsSubmenuVisible(true);
    } else {
      setIsSubmenuVisible(false);
    }
    setActiveMenuItem(menuItem);
    clearTimeout(submenuCloseTimeout);
  };

  const handleListItemMouseLeave = () => {
    submenuCloseTimeout = setTimeout(() => {
      setIsSubmenuVisible(false);
    }, 500);
  };

  const handleSubmenuItemClicked = () => {
    setIsSubmenuItemClicked(true);
    setIsSubmenuVisible(false);
  };

  const handleSubmenuMouseEnter = () => {
    clearTimeout(submenuCloseTimeout);
  };

  const submenuLeftPosition = isSmallScreen ? 60 : drawerWidth;

  const commonContainerStyle = {
    display: "flex",
  };

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const submenuStyle = {
    width: submenuWidth,
    height: "100vh",
    position: "absolute",
    left: submenuLeftPosition,
    transition: "left 0.5s",
    top: `${scrollY}px`,
    backgroundColor: colors.black[100],
    borderRight: "0.5px solid " + colors.black[300],
    zIndex: 1000,
  };

  const handleMenuMouseLeave = () => {
    if (!isSubmenuVisible && !isSubmenuItemClicked) {
      setActiveMenuItem(null);
    }
  };

  return (
    <>
      <div onMouseLeave={handleMenuMouseLeave}>
        <Container style={commonContainerStyle}>
          <Drawer
            variant="permanent"
            sx={{
              width: isSmallScreen ? 60 : drawerWidth,
              flexShrink: 0,
              transition: "width 0.5s",
              "& .MuiDrawer-paper": {
                width: isSmallScreen ? 60 : drawerWidth,
                transition: "width 0.5s",
              },
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <List>
                <MenuListItem
                  text="Home"
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={
                    <HomeRoundedIcon style={{ color: colors.black[900] }} />
                  }
                  to="/"
                />
                <MenuListItem
                  text="Games"
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("Games")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={
                    <ExtensionRoundedIcon
                      style={{ color: colors.black[900] }}
                    />
                  }
                />
                {/*<MenuListItem
                  text="Learn"
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("Learn")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={
                    <GrassRoundedIcon style={{ color: colors.black[900] }} />
                  }
                />*/}
                <MenuListItem
                  text="Library"
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("Library")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={
                    <LocalLibraryRoundedIcon
                      style={{ color: colors.black[900] }}
                    />
                  }
                />
                <MenuListItem
                  text="Analytics"
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("Analytics")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={<InsightsIcon style={{ color: colors.black[900] }} />}
                />

                <MenuListItem
                  text="Tools"
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("Tools")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={<HandymanIcon style={{ color: colors.black[900] }} />}
                />
                <MenuListItem
                  text="About"
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onMouseEnter={() => handleListItemMouseEnter("")}
                  onMouseLeave={handleListItemMouseLeave}
                  icon={<InfoIcon style={{ color: colors.black[900] }} />}
                  to="/about"
                />
                {userData ? (
                  <MenuListItem
                    text={userData?.username}
                    isSmallScreen={isSmallScreen}
                    activeMenuItem={activeMenuItem}
                    onMouseEnter={() => handleListItemMouseEnter("Account")}
                    onMouseLeave={handleListItemMouseLeave}
                    icon={
                      auth && userData?.photoURL ? (
                        <img
                          src={userData?.photoURL}
                          alt="User Profile"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <AccountCircleRoundedIcon
                          style={{ color: colors.black[900] }}
                        />
                      )
                    }
                  />
                ) : (
                  <MenuListItem
                    text="Login"
                    isSmallScreen={isSmallScreen}
                    activeMenuItem={activeMenuItem}
                    onMouseEnter={() => handleListItemMouseEnter("")}
                    onMouseLeave={handleListItemMouseLeave}
                    icon={
                      <AccountCircleRoundedIcon
                        style={{ color: colors.black[900] }}
                      />
                    }
                    to="/login"
                  />
                )}
                <MenuListItem
                  onMouseEnter={() => handleListItemMouseEnter("")}
                  onMouseLeave={handleListItemMouseLeave}
                  isSmallScreen={isSmallScreen}
                  activeMenuItem={activeMenuItem}
                  onClick={colorMode.toggleColorMode}
                  text={
                    theme.palette.mode === "dark" ? "Dark Mode" : "Light Mode"
                  }
                  icon={
                    theme.palette.mode === "dark" ? (
                      <DarkModeOutlinedIcon />
                    ) : (
                      <LightModeOutlinedIcon />
                    )
                  }
                />
              </List>

              {!isSmallScreen && (
                <Box
                  sx={{
                    padding: "16px",
                    color: colors.black[600],
                    fontStyle: "italic",
                    fontSize: "1rem",
                    borderLeft: `4px solid ${colors.black[300]}`,
                    margin: "16px 10px",
                    lineHeight: "1.6",
                  }}
                >
                  <div>"{quote.Quote}"</div>
                  <div
                    style={{
                      marginTop: "8px",
                      fontStyle: "normal",
                    }}
                  >
                    - {quote.Author}
                  </div>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                paddingBottom: "0px",
                paddingLeft: "8px",
              }}
            >
              <List>
                <IconButton
                  component="a"
                  href="https://patreon.com/ChessboardMagic"
                  target="_blank"
                  sx={{ color: colors.black[900], marginRight: "8px" }}
                >
                  <PatreonIcon />
                </IconButton>

                <IconButton
                  component="a"
                  href="https://www.facebook.com/chessboardmagic"
                  target="_blank"
                  sx={{ color: "#1877f2", marginRight: "8px" }}
                >
                  <FacebookIcon />
                </IconButton>

                <IconButton
                  component="a"
                  href="https://www.twitter.com/chessboardmagic"
                  target="_blank"
                  sx={{ color: "#1DA1F2", marginRight: "8px" }}
                >
                  <TwitterIcon />
                </IconButton>
              </List>
            </Box>
          </Drawer>
        </Container>
      </div>
      <Box
        sx={submenuStyle}
        style={{
          display: isSubmenuVisible ? "block" : "none",
        }}
        onMouseEnter={handleSubmenuMouseEnter}
        onMouseLeave={() => setIsSubmenuVisible(false)}
      >
        <List>
          {activeMenuItem === "Account" && (
            <>
              <SubmenuItem
                text="Edit Profile"
                to="/profile"
                onClick={handleSubmenuItemClicked}
                icon={
                  <SettingsRoundedIcon style={{ color: colors.black[900] }} />
                }
              />
              <SubmenuItem
                text="Logout"
                to="/"
                onClick={logout}
                icon={
                  <LogoutRoundedIcon style={{ color: colors.black[900] }} />
                }
              />
            </>
          )}
          {activeMenuItem === "Learn" && (
            <>
              <SubmenuItem
                text="Chess Principles"
                to="/chessprinciples"
                onClick={handleSubmenuItemClicked}
                icon={<HubRoundedIcon style={{ color: colors.black[900] }} />}
              />
              <SubmenuItem
                text="Chess Openings"
                to="/chessopenings"
                onClick={handleSubmenuItemClicked}
                icon={
                  <AutoStoriesRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              <SubmenuItem
                text="Chess Resources"
                to="/chessresources"
                onClick={handleSubmenuItemClicked}
                icon={<BookmarksIcon style={{ color: colors.black[900] }} />}
              />
            </>
          )}
          {activeMenuItem === "Analytics" && (
            <>
              <SubmenuItem
                text="User Scout"
                to="/userscout"
                onClick={handleSubmenuItemClicked}
                icon={
                  <TroubleshootRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              {/*
              <SubmenuItem
                text="My Profile"
                to="/myprofile"
                onClick={handleSubmenuItemClicked}
                icon={
                  <TroubleshootRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              <SubmenuItem
                text="Opponent Prep"
                to="/opponentprep"
                onClick={handleSubmenuItemClicked}
                icon={
                  <CenterFocusStrongRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />*/}
            </>
          )}
          {activeMenuItem === "Tools" && (
            <>
              <SubmenuItem
                text="Notation Trainer"
                to="/notationtrainer"
                onClick={handleSubmenuItemClicked}
                icon={<EditNoteIcon style={{ color: colors.black[900] }} />}
              />
              <SubmenuItem
                text="Fischer Random"
                to="/fischerrandom"
                onClick={handleSubmenuItemClicked}
                icon={
                  <ShuffleRoundedIcon style={{ color: colors.black[900] }} />
                }
              />
              <SubmenuItem
                text="GIF Generator"
                to="/GifGenerator"
                onClick={handleSubmenuItemClicked}
                icon={<GifIcon style={{ color: colors.black[900] }} />}
              />
              <SubmenuItem
                text="Opening Explorer"
                to="/openingexplorer"
                onClick={handleSubmenuItemClicked}
                icon={<AccountTreeIcon style={{ color: colors.black[900] }} />}
              />
              {/*<SubmenuItem
                text="ECF Ratings Explorer"
                to="/ecfratingsexplorer"
                onClick={handleSubmenuItemClicked}
                icon={<StorageIcon style={{ color: colors.black[900] }} />}
              />*/}
            </>
          )}
          {activeMenuItem === "Games" && (
            <>
              <SubmenuItem
                text="Play The Opening"
                to="/playtheopening"
                onClick={handleSubmenuItemClicked}
                icon={
                  <PlayCircleFilledRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />

              <SubmenuItem
                text="Guess The Opening"
                to="/guesstheopening"
                onClick={handleSubmenuItemClicked}
                icon={
                  <GridViewRoundedIcon style={{ color: colors.black[900] }} />
                }
              />

              <SubmenuItem
                text="Guess The Elo"
                to="/guesstheelo"
                onClick={handleSubmenuItemClicked}
                icon={
                  <HelpOutlineRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />

              <SubmenuItem
                text="Guess The Eval"
                to="/guesstheeval"
                onClick={handleSubmenuItemClicked}
                icon={<IsoIcon style={{ color: colors.black[900] }} />}
              />

              <SubmenuItem
                text="Chess Anagrams"
                to="/anagrams"
                onClick={handleSubmenuItemClicked}
                icon={<AbcRoundedIcon style={{ color: colors.black[900] }} />}
              />

              <SubmenuItem
                text="Chess Hangman"
                to="/hangman"
                onClick={handleSubmenuItemClicked}
                icon={
                  <SettingsInputAntennaRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />

              <SubmenuItem
                text="Chess Wordsearch"
                to="/wordsearch"
                onClick={handleSubmenuItemClicked}
                icon={
                  <PatternRoundedIcon style={{ color: colors.black[900] }} />
                }
              />
              <SubmenuItem
                text="Chess Crossword"
                to="/crossword"
                onClick={handleSubmenuItemClicked}
                icon={<CrosswordIcon style={{ color: colors.black[900] }} />}
              />

              <SubmenuItem
                text="Chess Slide"
                to="/chessslide"
                onClick={handleSubmenuItemClicked}
                icon={
                  <OpenWithRoundedIcon style={{ color: colors.black[900] }} />
                }
              />

              <SubmenuItem
                text="Image Puzzle"
                to="/imagepuzzle"
                onClick={handleSubmenuItemClicked}
                icon={<CropOriginalIcon style={{ color: colors.black[900] }} />}
              />
            </>
          )}
          {activeMenuItem === "Admin" && (
            <>
              <SubmenuItem
                text="Import ECO"
                to="/admin/importeco"
                onClick={handleSubmenuItemClicked}
                icon={
                  <ImportExportRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              <SubmenuItem
                text="Import Chess Terms"
                to="/admin/importchessterms"
                onClick={handleSubmenuItemClicked}
                icon={
                  <ImportExportRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              <SubmenuItem
                text="Import Chess Quotes"
                to="/admin/importchessquotes"
                onClick={handleSubmenuItemClicked}
                icon={
                  <ImportExportRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              <SubmenuItem
                text="Import PGN"
                to="/admin/importpgn"
                onClick={handleSubmenuItemClicked}
                icon={
                  <ImportExportRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              {/*<SubmenuItem
                text="Wordsearch Book"
                to="/admin/wordsearchbook"
                onClick={handleSubmenuItemClicked}
                icon={
                  <PatternRoundedIcon style={{ color: colors.orange[500] }} />
                }
              />*/}
            </>
          )}
          {activeMenuItem === "Library" && (
            <>
              <SubmenuItem
                text="World Championships"
                to="/worldchampionships"
                onClick={handleSubmenuItemClicked}
                icon={
                  <EmojiEventsRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              <SubmenuItem
                text="Classic Games"
                to="/classicgames"
                onClick={handleSubmenuItemClicked}
                icon={<HistoryIcon style={{ color: colors.black[900] }} />}
              />
              <SubmenuItem
                text="Chess Glossary"
                to="/glossary"
                onClick={handleSubmenuItemClicked}
                icon={
                  <FormatListBulletedRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              <SubmenuItem
                text="Chess Quotes"
                to="/quotes"
                onClick={handleSubmenuItemClicked}
                icon={
                  <FormatQuoteRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              <SubmenuItem
                text="Basic Rules Of Play"
                to="/basicrulesofplay"
                onClick={handleSubmenuItemClicked}
                icon={<GavelRoundedIcon style={{ color: colors.black[900] }} />}
              />
              <SubmenuItem
                text="Competitive Rules Of Play"
                to="/competitiverulesofplay"
                onClick={handleSubmenuItemClicked}
                icon={<CompetitionIcon style={{ color: colors.black[900] }} />}
              />
              {/*
              
              <SubmenuItem
                text="Classic Games"
                to="/classicgames"
                onClick={handleSubmenuItemClicked}
                icon={
                  <HistoryRoundedIcon style={{ color: colors.black[900] }} />
                }
              />
              <SubmenuItem
                text="Immortal Games"
                to="/immortalgames"
                onClick={handleSubmenuItemClicked}
                icon={
                  <AllInclusiveRoundedIcon
                    style={{ color: colors.black[900] }}
                  />
                }
              />
              <SubmenuItem
                text="Grandmaster Games"
                to="/grandmastergames"
                onClick={handleSubmenuItemClicked}
                icon={<RocketRoundedIcon style={{ color: colors.black[900] }} />}
              />*/}
            </>
          )}
        </List>
      </Box>
    </>
  );
};

export default AppSideBar;
