import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const GuessTheOpeningHelp = () => {
  return (
    <Box>
      <Typography>
        "Guess the Opening" is an exciting chess challenge that puts your
        knowledge of chess openings to the test! In this game, you'll explore
        popular chess openings, play moves on the board, and use your strategic
        thinking to guess the correct opening line.
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Select Your Game Length"
            secondary="Decide how long or short you want the chess game to be. You can start with a quick game or a longer one."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Play the Moves"
            secondary="Put the chess pieces on the board to match the starting positions of your favorite chess openings. Take your time and follow the opening's steps."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Guess the Opening"
            secondary="After you've set up the board with your moves, click the Guess button. Try to match the opening sequence correctly."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Check Your Guess"
            secondary={
              "The game will provide feedback based on your guess. If you guess the correct move in the correct position, your guess will turn GREEN. If you guessed the correct move but in the wrong order, the square will turn YELLOW"
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Multiple Attempts"
            secondary="Don't worry if you don't get it right the first time! You have six chances to guess the entire opening sequence. Keep practicing and improving your skills."
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default GuessTheOpeningHelp;
