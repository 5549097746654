import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

const ImagePuzzleHelp = () => {
  return (
    <>
      <Typography>
        Welcome to Chess Image Puzzle! In this engaging game, you'll move
        squares to reveal the image of a famous chess player. Test your
        puzzle-solving skills and chess knowledge!
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Move the Squares"
            secondary="Drag and drop the squares to rearrange them and form the complete image. Each square is part of the picture of a famous chess player. Arrange the squares correctly to reveal the image!"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Learn About the Player"
            secondary="Once you've revealed the image, you can click on the player’s picture to learn more about their achievements and contributions to the world of chess."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Start a New Game"
            secondary="You can start a new game anytime by clicking the play button. Each game presents a new puzzle to solve, keeping the challenge fresh and exciting!"
          />
        </ListItem>
      </List>
    </>
  );
};

export default ImagePuzzleHelp;
