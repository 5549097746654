import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const CrosswordHelp = () => {
  return (
    <Box>
      <Typography>
        Welcome to the Chess Crossword Puzzle! This fun and educational game
        combines chess knowledge with a classic crossword challenge. Follow
        these instructions to get started:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Start the Puzzle"
            secondary="Click the 'Play' button to generate a new chess-themed crossword puzzle. Each puzzle will contain words related to chess terms and concepts."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Fill in the Blanks"
            secondary="Click on the empty cells in the crossword grid to type in letters. Each cell can hold one letter, and your goal is to complete the words based on the provided hints."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Understand the Hints"
            secondary="Hints for the words are displayed in two categories: Across and Down. Use these hints to figure out the words and fill them in correctly."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Check Your Answers"
            secondary="Click the 'Check' button to verify if your answers are correct. If the crossword is incorrect, an alert will notify you to try again. If the crossword is correct, you'll see a success message."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Reset the Puzzle"
            secondary="If you want to start over, click the 'Reset' button to clear all the inputs and try again from scratch."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Have Fun!"
            secondary="Enjoy solving the Chess Crossword Puzzle and enhance your chess knowledge while having fun. Don't give up, and keep practicing to improve your skills!"
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default CrosswordHelp;
