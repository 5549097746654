import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const ChessSlideHelp = () => {
  return (
    <Box>
      <Typography>
        "Chess Slide Puzzle" is a fun and educational game that combines chess
        with a classic sliding puzzle challenge. Get ready to solve puzzles and
        learn about chess openings in an exciting new way!
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Look at the Puzzle"
            secondary="You'll see a 4x4 grid with chess pieces that represent a chess opening. Don't worry if you're new to chess; we'll guide you through it."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Move the Pieces"
            secondary="Click on a square next to the empty one to slide the puzzle pieces. Your goal is to arrange them correctly to recreate the chess opening."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Complete the Opening"
            secondary="Keep moving the pieces until you've successfully recreated the chess opening. It's like putting together a puzzle."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Get Help with Coordinates"
            secondary="Hover your mouse over a Chess piece to see its coordinates on the chessboard."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Solve the Puzzle"
            secondary="Click on Check to see how many squares are in the correct position. Don't give up! Keep sliding the pieces until you've completed the chess opening. It might take a few tries, but that's all part of the fun!"
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default ChessSlideHelp;
