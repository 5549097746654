import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const GuessTheEvalHelp = () => {
  const listItemTextStyle = {
    fontSize: "0.875rem",
    color: "rgba(0, 0, 0, 0.6)",
  };

  return (
    <Box>
      <Typography component="div">
        "Guess the Eval" is an exciting chess challenge that tests your ability
        to evaluate chess positions. Sharpen your chess skills by predicting the
        evaluation of various chess scenarios and see how accurate you are!
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Start the Game"
            secondary="Click on Play to be presented with a random Chess position. Analyze the position carefully before making your guess."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Make Your Guess"
            secondary={
              <>
                <Typography component="div" style={listItemTextStyle}>
                  Choose one of the available options to predict the evaluation
                  of the position:
                </Typography>
                <Box component="div" sx={{ pl: 2 }}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="White is Winning"
                        secondary="Evaluation value: +2 or Higher"
                        style={listItemTextStyle}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="White is Better"
                        secondary="Evaluation value: +0.5 to +1.99"
                        style={listItemTextStyle}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Position is Equal"
                        secondary="Evaluation value: -0.49 to +0.49"
                        style={listItemTextStyle}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Black is Better"
                        secondary="Evaluation value: -0.5 to -1.99"
                        style={listItemTextStyle}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Black is Winning"
                        secondary="Evaluation value: -2 or Lower"
                        style={listItemTextStyle}
                      />
                    </ListItem>
                  </List>
                </Box>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Check Your Evaluation"
            secondary={
              <Typography component="div" style={listItemTextStyle}>
                The evaluations are calculated using Stockfish 16.1. While there
                might be slight differences between engines, the overall
                evaluation of the position should be consistent.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Improve Your Skills"
            secondary="Don't worry if you don't get it right the first time! Each attempt helps you improve your understanding of chess positions. Keep practicing and enhance your evaluation skills."
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default GuessTheEvalHelp;
