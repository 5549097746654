import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const WordsearchHelp = () => {
  return (
    <Box>
      <Typography>
        "Chess Wordsearch" is a fun and educational game that combines the
        excitement of word searches with the rich vocabulary of chess
        terminology. Put on your thinking cap and sharpen your chess knowledge
        as you hunt for chess-related words hidden in a grid of letters.
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Find Chess Words"
            secondary="You'll see a grid filled with letters. Your job is to hunt for chess-related words hidden in the grid."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Spot the Words"
            secondary="Carefully scan the grid to discover chess terms. These words can be hidden in all directions - left to right, up and down, or even in reverse!"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Highlight Words"
            secondary="To select a word, simply click on it or drag your mouse over the letters that make up the word. The word will then appear and be added to your list of found words, along with its meaning."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Complete the Challenge"
            secondary="Keep looking for chess words until you've found them all. The challenge is to find them as quickly as you can."
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default WordsearchHelp;
