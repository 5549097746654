import React, { useState, useEffect, useCallback } from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";
import { useUser } from "../../../context/UserContext";

import ContentHeader from "../../../components/ContentHeader";
import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
import {
  eco_1,
  eco_2,
  eco_3,
  eco_4,
  eco_5,
  eco_6,
  eco_7,
  eco_8,
  eco_9,
  eco_10,
  eco_11,
  eco_12,
} from "../../../data/eco/eco";

function OpeningExplorer() {
  const [game, setGame] = useState(new Chess());
  const [pgn, setPgn] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [lines, setLines] = useState(eco_1);
  const [orientation, setOrientation] = useState("white");
  const { userData } = useUser();

  const handleResetBoard = () => {
    const newGame = new Chess();
    setPgn("");
    setLines(eco_1);
    setGame(newGame);
  };

  function onDrop(sourceSquare, targetSquare) {
    try {
      const move = game.move({
        from: sourceSquare,
        to: targetSquare,
      });

      // Check if the move is valid
      if (move === null) {
        return false; // Prevent the move from being accepted
      }

      let history = game.history();
      let formattedHistory = "";
      for (let i = 0; i < history.length; i += 2) {
        const moveNumber = Math.floor(i / 2) + 1;
        const whiteMove = history[i];
        const blackMove = history[i + 1] ? history[i + 1] : "";
        formattedHistory += `${moveNumber}. ${whiteMove} ${blackMove} `;
      }
      let newPgn = formattedHistory.trim();
      setPgn(newPgn);

      if (history.length === 0) {
        setLines(eco_1);
      } else {
        const mergedEcos = [
          ...eco_1,
          ...eco_2,
          ...eco_3,
          ...eco_4,
          ...eco_5,
          ...eco_6,
          ...eco_7,
          ...eco_8,
          ...eco_9,
          ...eco_10,
          ...eco_11,
          ...eco_12,
        ];

        setLines(filterEcosByPgn(newPgn, mergedEcos));
      }
      return true; // Accept the move
    } catch (error) {
      return false; // Prevent the move from being accepted
    }
  }

  const flipOrientation = () => {
    setOrientation((prevOrientation) =>
      prevOrientation === "white" ? "black" : "white"
    );
  };
  const handleUndo = useCallback(() => {
    game.undo();

    let history = game.history();
    let formattedHistory = "";
    for (let i = 0; i < history.length; i += 2) {
      const moveNumber = Math.floor(i / 2) + 1;
      const whiteMove = history[i];
      const blackMove = history[i + 1] ? history[i + 1] : "";
      formattedHistory += `${moveNumber}. ${whiteMove} ${blackMove} `;
    }
    let newPgn = formattedHistory.trim();
    setPgn(newPgn);

    if (history.length === 0) {
      setLines(eco_1);
    } else {
      const mergedEcos = [
        ...eco_1,
        ...eco_2,
        ...eco_3,
        ...eco_4,
        ...eco_5,
        ...eco_6,
        ...eco_7,
        ...eco_8,
        ...eco_9,
        ...eco_10,
        ...eco_11,
        ...eco_12,
      ];

      setLines(filterEcosByPgn(newPgn, mergedEcos));
    }
  }, [game]);

  useEffect(() => {
    // Add event listeners for left and right arrow keys
    const handleKeyPress = (e) => {
      if (e.key === "ArrowLeft") {
        handleUndo();
      }
    };

    // Attach the event listener to the window
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleUndo]);

  const filterEcosByPgn = (pgnString, ecos) => {
    return ecos.filter((eco) => eco.pgn.startsWith(pgnString)).slice(0, 20);
  };

  return (
    <Box>
      <ContentHeader
        title="Opening Explorer"
        subtitle="Explore a wide range of chess openings, and deepen your understanding of variations from the very first moves"
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
      />
      <Helmet>
        <title>Opening Explorer</title>
        <meta
          name="description"
          content="Explore a wide range of chess openings, and deepen your understanding of variations from the very first moves."
        />
        <meta property="og:title" content="Opening Explorer" />
        <meta
          property="og:description"
          content="Explore a wide range of chess openings, and deepen your understanding of variations from the very first moves."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/tools/openingexplorer.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/openingexplorer`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Opening Explorer" />
        <meta
          name="twitter:description"
          content="Explore a wide range of chess openings, and deepen your understanding of variations from the very first moves."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/tools/openingexplorer.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleResetBoard}
          style={{ marginRight: 10 }}
          startIcon={
            <RestartAltRoundedIcon style={{ color: colors.black[900] }} />
          }
        >
          Reset
        </Button>
        <Button
          variant="contained"
          onClick={handleUndo}
          style={{ marginRight: 10 }}
        >
          Undo
        </Button>
        <Button
          variant="contained"
          onClick={flipOrientation}
          style={{ marginRight: 10 }}
        >
          Flip
        </Button>
        <Box
          sx={{
            p: "20px 0px 0px 0px",
            display: "flex",
            flexDirection: {
              xs: "column", // All screens smaller than 'sm'
              sm: "column", // All screens smaller than 'md'
              md: "row", // Medium screens and larger
            },
            alignItems: "flex-start", // Align items at the start of the flex container
            width: "100%", // Use the full width of the container
          }}
        >
          <div
            id="chessboard"
            style={{
              width: "100%", // Full width in column layout
              maxWidth: "500px", // Maximum width to constrain the chessboard
              padding: "0px 10px 10px 0px", // Uniform padding
              boxSizing: "border-box", // Include padding and border in the element's total width and height
            }}
          >
            <Chessboard
              position={game.fen()}
              onPieceDrop={onDrop}
              boardOrientation={orientation}
              customLightSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "Modern Minimal"].lightSquare,
                backgroundImage:
                  themeColors[userData?.theme || "Modern Minimal"]
                    .lightSquarePattern,
              }}
              customDarkSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "Modern Minimal"].darkSquare,
                backgroundImage:
                  themeColors[userData?.theme || "Modern Minimal"]
                    .darkSquarePattern,
              }}
              customPieces={pieceSets[userData?.pieceset || "Maestro"]}
              areArrowsAllowed={false}
            />
            <Typography sx={{ marginTop: "10px" }}>{pgn}</Typography>
          </div>

          <div
            id="description"
            style={{
              flex: 1,
              overflow: "auto",
              maxWidth: "500px",
              padding: "0px 0px 0px 0px", // Uniform padding
            }}
          >
            <ol style={{ marginTop: 0, paddingLeft: 0, listStyleType: "none" }}>
              {lines.map((eco, index) => (
                <li key={index}>
                  {eco.name} <strong>{eco.pgn}</strong>
                </li>
              ))}
            </ol>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default OpeningExplorer;
