import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

const AnagramsHelp = () => {
  return (
    <>
      <Typography>
        Welcome to Chess Anagrams! In this exciting game, you'll be challenged
        to unscramble letters to form a chess-related word. Whether it's a chess
        term, player, opening, or engine, put your chess knowledge and
        word-solving skills to the test!
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Unscramble the Letters"
            secondary="Drag and drop the letters to form a correct word. Each letter is part of a chess-related term, player, opening, or engine. Arrange the letters correctly to complete the word!"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Use the Hint"
            secondary="If you're stuck, you can use the hint button to get a clue about the type of word you are trying to form. The hint button will disappear after the first use and will be replaced by a give up button."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Quit"
            secondary="If you still can't figure out the word, you can use the give up button to reveal the correct arrangement of the letters and complete the game."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Chess Knowledge"
            secondary="This game tests your familiarity with chess terms, players, openings, and engines. The more you know, the better your chances of unscrambling the word!"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Start a New Game"
            secondary="You can start a new game anytime by clicking the play button. Each game presents a new word to unscramble, keeping the challenge fresh and exciting!"
          />
        </ListItem>
      </List>
    </>
  );
};

export default AnagramsHelp;
