// pgnUtils.js

export function processPGN(rawPGN) {
  const cleanedPGN = cleanPGN(rawPGN);
  const pgnMovesArray = splitMoves(cleanedPGN);
  const rawSplit = splitRawPGN(rawPGN, pgnMovesArray);
  const squareColours = extractSquareColours(rawSplit);
  const arrowColours = extractArrowColours(rawSplit);
  const comments = extractComments(rawSplit);

  return { cleanedPGN, squareColours, arrowColours, comments };
}

function cleanPGN(rawPGN) {
  // Remove all comments enclosed in {}
  let cleanedPGN = rawPGN.replace(/\{[^}]*\}/g, "");
  // Remove all N... notations
  cleanedPGN = cleanedPGN.replace(/\d+\.\.\.\s*/g, "");
  // Remove all $x notations
  cleanedPGN = cleanedPGN.replace(/\$\d+/g, "");
  // Trim extra spaces and newlines
  cleanedPGN = cleanedPGN.replace(/\s+/g, " ").trim();
  return cleanedPGN;
}

function splitMoves(cleanedPGN) {
  // Split the PGN into move sections, filtering out empty strings
  const moveSections = cleanedPGN.split(/\d+\.\s*/).filter(Boolean);
  // Initialize the final array to hold individual moves
  const finalMovesArray = [];
  // Split each move section into individual moves and add to the final array
  moveSections.forEach((section) => {
    const moves = section.trim().split(/\s+/).filter(Boolean);
    finalMovesArray.push(...moves);
  });
  return finalMovesArray;
}

function splitRawPGN(rawPGN, pgnMovesArray) {
  const result = [];
  let currentIndex = 0;
  let insideComment = false;

  for (let i = 0; i < pgnMovesArray.length - 1; i++) {
    const currentMove = pgnMovesArray[i];
    const nextMove = pgnMovesArray[i + 1];
    let currentMovePosition = -1;
    let nextMovePosition = -1;

    for (let j = currentIndex; j < rawPGN.length; j++) {
      const char = rawPGN[j];

      if (char === "{") {
        insideComment = true;
      } else if (char === "}") {
        insideComment = false;
      }

      const substring = rawPGN.substring(j);

      if (
        !insideComment &&
        currentMovePosition === -1 &&
        substring.startsWith(currentMove)
      ) {
        currentMovePosition = j;
      } else if (
        !insideComment &&
        currentMovePosition !== -1 &&
        nextMovePosition === -1 &&
        substring.startsWith(nextMove)
      ) {
        nextMovePosition = j;
        currentIndex = nextMovePosition; // Update currentIndex to continue from here
        break;
      }
    }

    if (currentMovePosition !== -1 && nextMovePosition !== -1) {
      result.push(rawPGN.substring(currentMovePosition, nextMovePosition));
    }
  }

  return result;
}

function extractSquareColours(pgnArray) {
  return pgnArray.map((move) => {
    const match = move.match(/\[%csl ([^\]]+)\]/);
    return match ? match[1] : "";
  });
}

function extractArrowColours(pgnArray) {
  return pgnArray.map((move) => {
    const match = move.match(/\[%cal ([^\]]+)\]/);
    return match ? match[1] : "";
  });
}

function extractComments(pgnArray) {
  return pgnArray.map((move) => {
    const match = move.match(/\{([^%}]*?)\}/);
    return match ? match[1].trim() : "";
  });
}
