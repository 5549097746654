import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

const NotationTrainerHelp = () => {
  return (
    <>
      <Typography>
        Welcome to Chess Notation Trainer! This interactive game helps you
        master chess notation by practicing with random games. Improve your
        skills and learn the names of chess openings!
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Click Play to Load a Random Game"
            secondary="Start by clicking the play button to load a random chess game. This will set up the board and prepare you for notation practice."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Type the Move That is Played"
            secondary="As the game progresses, type the moves in standard chess notation."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Learn the Name of the Opening"
            secondary="As you enter moves, the system will identify the opening being played. This helps you familiarize yourself with different chess openings and their names."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Practice Your Notation Skills"
            secondary="This game is designed to improve your ability to record and read chess notation, an essential skill for any serious chess player."
          />
        </ListItem>
      </List>
    </>
  );
};

export default NotationTrainerHelp;
